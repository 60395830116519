import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBNavbarLink,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBBtn,
} from "mdb-react-ui-kit";
import Login from "./Login";
import Sub_heading from "./Sub-heading";
import Pca from "./Pca";
import SupportImage from "./SupportImage";
import Benefits from "./Benefits";
import SocialProof from "./SocialProof";
import Navigation from "./Navigation";
import ContentOffer from "./ContentOffer";
import Sca from "./Sca";
import Features from "./Features";
import Resources from "./Resources";
import Heading from "./Heading";
import SuccessIndicates from "./SuccessIndicates";

import DrawerAppBar from "./Navbar";
import Footer from "./Footer";
import ContactForm from "./Contact";
import SignUp from "./SignUp";
import Services from "./Services";
import ProfilePage from "./UserProfile";
import BookNow from "./BookNow";
import { WhatIsDigitalMarketing } from "./WhatIsDigitalMarketing";

export default function App() {
  return (
    <>
      <DrawerAppBar />
      <Heading />
      <WhatIsDigitalMarketing />

      <Pca />

      <ContactForm />

      <Footer />
    </>
  );
}
