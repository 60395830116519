import { Box, Button, Grid } from "@mui/material";
import React from "react";
import img1 from "../image/1.gif";
import img2 from "../image/2.gif";
import img3 from "../image/3.gif";
import ServiceTables from "./services-2";

export const WhatIsDigitalMarketing = () => {
  return (
    <>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <img className="img" src={img1} alt="logo" />
        </Grid>

        <Grid item lg={6} xs={12}>
          <p className="subheading">What Is Digital Marketing Consultent.</p>
          <p>
            A digital marketing consultant is a professional who provides expert
            advice, guidance, and strategies to businesses or individuals
            seeking to improve their online presence, reach their target
            audience effectively, and achieve their marketing goals in the
            digital realm. Their role involves assessing the client's current
            digital marketing efforts, analyzing data and metrics, identifying
            opportunities for improvement, and developing customized strategies
            tailored to the client's needs and objectives. Digital marketing
            consultants may specialize in specific areas such as SEO, PPC,
            social media, content marketing, email marketing, or overall digital
            strategy. They often stay updated on the latest trends, tools, and
            best practices in digital marketing to offer valuable insights and
            recommendations. Additionally, they may provide training and support
            to clients' internal teams to help them execute the strategies
            effectively. Ultimately, digital marketing consultants aim to drive
            results and maximize return on investment for their clients' digital
            marketing efforts.
          </p>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={6} xl={12}>
          <p className="subheading">Why Choose Avira For Digital Marketing.</p>
          <p>
            Choosing us for digital marketing ensures tailored strategies
            crafted to elevate your brand's online presence. Our team comprises
            seasoned experts adept in SEO, PPC, social media, and content
            marketing, delivering comprehensive solutions aligned with your
            goals. With a focus on innovation and data-driven insights, we
            optimize campaigns for maximum ROI and audience engagement. Our
            collaborative approach ensures transparency, timely communication,
            and flexibility to adapt to evolving market trends. Moreover, we
            prioritize client satisfaction, offering personalized attention and
            continuous support to drive sustainable growth and success.
            Partnering with us empowers your brand to thrive in the competitive
            digital landscape with confidence and distinction.
          </p>
        </Grid>
        <Grid item lg={6} xl={12}>
          <img className="img" src={img2} alt="logo" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid lg={6} xl={12}>
          <img className="img" src={img3} alt="logo" />
        </Grid>
        <Grid lg={6} xl={12}>
          <p className="subheading">Avira Is Recognized By.</p>
          <p>
            Avira, a well-known cybersecurity company, has been recognized by
            various popular companies and organizations for its excellence in
            providing antivirus and security solutions. Some of these
            recognitions include: AV-Test: Avira has consistently received high
            scores in independent antivirus testing conducted by AV-Test, a
            leading organization that evaluates antivirus software for
            effectiveness, performance, and usability. AV-Comparatives: Avira
            has also been recognized by AV-Comparatives, another reputable
            independent testing organization, for its antivirus capabilities and
            overall security performance. PCMag: Avira has been featured and
            recommended by PCMag, a prominent technology publication, for its
            antivirus software's effectiveness and user-friendly interface.
            TechRadar: Avira has been positively reviewed by TechRadar, a
            popular technology news and reviews website, for its antivirus
            protection and additional security features. Trustpilot: Avira has
            received positive ratings and reviews from customers on Trustpilot,
            a well-known consumer review platform, reflecting its reputation for
            reliability and customer satisfaction.
          </p>
        </Grid>
      </Grid>
      <Box class="services-structure">
        <Box className="subheading-content  ">
          <p className="subheading">
            Avira Digital Marketing consulting services
          </p>
          <p className="sub-subheading">Strategy Development</p>
          <p>
            Crafting comprehensive digital marketing strategies aligned with
            business goals and target audience demographics.
          </p>
          <p className="sub-subheading">Market Research and Analysis</p>
          <p>
            Conducting market research, competitor analysis, and audience
            segmentation to inform digital marketing strategies.
          </p>
          <p className="sub-subheading">SEO Consulting</p>
          <p>
            Optimizing websites for search engines to improve visibility and
            organic traffic through keyword research, on-page optimization, and
            link building strategies.
          </p>

          <p className="sub-subheading">PPC Advertising Consulting</p>
          <p>
            Advising on pay-per-click advertising campaigns, including keyword
            selection, ad copywriting, bidding strategies, and budget
            allocation.
          </p>
          <p className="sub-subheading">Social Media Consulting</p>
          <p>
            Developing social media strategies, content calendars, and
            engagement tactics to build brand awareness and drive audience
            engagement.
          </p>
          <p className="sub-subheading">Content Marketing Consulting</p>
          <p>
            Providing guidance on content creation, distribution, and promotion
            strategies to attract and retain target audiences.
          </p>
          <p className="sub-subheading">Email Marketing Consulting</p>
          <p>
            Assisting with email campaign strategy, list segmentation, email
            design, and performance tracking to maximize email marketing
            effectiveness.
          </p>
          <p className="sub-subheading">
            Analytics and Performance Measurement
          </p>
          <p>
            Implementing analytics tools, tracking key performance indicators
            (KPIs), and analyzing data to optimize digital marketing efforts and
            improve ROI.
          </p>
          <p className="sub-subheading">
            Conversion Rate Optimization (CRO) Consulting
          </p>

          <p>
            Evaluating website usability, conducting A/B testing, and
            implementing strategies to increase conversion rates and optimize
            user experience.
          </p>
          <p className="sub-subheading">
            Digital Branding and Reputation Management
          </p>
          <p>
            Developing strategies to enhance brand reputation, monitor online
            reviews, and manage online brand presence across digital channels.
          </p>
        </Box>
      </Box>
      <ServiceTables />
      <p className="subheading">Latest Blogs</p>
    </>
  );
};
